import { Locale } from 'vant'
import es from 'vant/es/locale/lang/es-ES'
import en from 'vant/es/locale/lang/en-US'

export default function ({ i18n }) {
  const locales = { es, en, pt: en }
  if (locales[i18n.locale]) {
    Locale.use(i18n.locale, locales[i18n.locale])
  } else {
    console.log('ignoring', i18n.locale, 'setting locale to es')
    Locale.use('es', es)
  }
}

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr)
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr)
  }
}
