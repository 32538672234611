import { Auth } from '@aws-amplify/auth'
import { Capacitor } from '@capacitor/core'
import * as Sentry from '@sentry/browser'
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import { increment } from 'firebase/firestore'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { Preferences } from '@capacitor/preferences'
let socket
export const state = () => ({
  notification: null,
  jobs: [],
  photos: [],
  order: null,
  device: null,
  bluetoothDevice: null,
  title: '',
  showTabbar: true,
  showBackButton: false,
  loading: false,
  authState: null,
  session: null,
  isNativePlatform: Capacitor.isNativePlatform(),
  users: null,
  rooms: [],
  messages: [],
  devices: [],
  pushToken: '',
  dispatchUsers: [],
  waitUnlock: false,
  company: null,
  traccarSession: null
})

export const mutations = {
  ...vuexfireMutations,
  SET_WAIT_UNLOCK (state, value) {
    state.waitUnlock = value
  },
  SET_NOTIFICATION (state, notification) {
    state.notification = notification
  },
  SET_PUSH_TOKEN (state, token) {
    state.pushToken = token
  },
  SET_USERS (state, users) {
    state.users = users
  },
  SET_TRACCAR_SESSION (state, session) {
    state.traccarSession = session
  },
  SET_SESSION (state, session) {
    state.session = session
    Sentry.setUser(session.accessToken.payload)
    this.$axios.setToken(session.accessToken.getJwtToken())
    this.$backend.setToken(session.accessToken.getJwtToken())
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_SHOW_TABBAR (state, show) {
    state.showTabbar = show
  },
  SET_SHOW_BACK_BUTTON (state, show) {
    state.showBackButton = show
  },
  SET_ORDER (state, order) {
    state.order = order
  },
  SET_TITLE (state, title) {
    state.title = title
  },
  SET_DEVICE (state, device) {
    state.device = device
  },
  SET_DEVICES (state, devices) {
    state.devices = devices
  },
  SET_BLUETOOTH_DEVICE (state, device) {
    state.bluetoothDevice = device
  },
  REMOVE_PHOTO (state, { jobId, photo }) {
    this.$fire.firestore.collection('jobs')
      .doc(jobId)
      .collection('photos')
      .doc(photo.id).delete()
  },
  SET_COMPANY (state, company) {
    state.company = company
  }
}

function getFirebaseMessage (image, title, body, token) {
  return {
    webpush: {
      fcmOptions: {
        link: 'https://driverchat.fleetmap.io'
      },
      notification: {
        title,
        body,
        icon: image
      }
    },
    token
    // data: { senderId: data.senderId }
  }
}

export const actions = {
  async takePhoto ({ state }) {
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100
      })

      const photoID = state.order
      const imageRef = this.$fire.storage.ref().child(photoID)
      await imageRef.put(await fetch(cameraPhoto.webPath).then(r => r.blob()))
      /* const doc = {
        photoID,
        photoURL: await imageRef.getDownloadURL()
      }
      await this.$fire.firestore.collection('jobs')
        .doc(state.order)
        .collection('photos')
        .add(doc)
       */
    } catch (e) {
      console.error(e)
    }
  },
  async logout ({ commit }) {
    console.log('logout')
    commit('SET_LOADING', true)
    await Auth.signOut()
    commit('SET_DEVICE', null)
    commit('SET_BLUETOOTH_DEVICE', null)
    commit('SET_LOADING', false)
    await this.$router.push('/')
  },
  updateOrder ({ state }, { orderId, order }) {
    return this.$fire.firestore.collection('jobs').doc(orderId).update(order)
  },
  clearUnread ({ state }, roomId) {
    return this.$fire.firestore.collection('rooms').doc(`${roomId}`).update({
      driverUnreadCount: 0
    })
  },
  addPushToken: firestoreAction(function ({ getters }) {
    return this.$fire.firestore.collection('users').doc(getters.user).set({
      id: getters.user,
      pushToken: getters.pushToken,
      lastUpdate: new Date()
    })
  }),
  sendMessage: firestoreAction(async function ({ state, getters }, message) {
    await this.$fire.firestore.collection('rooms').doc(`${message.roomId}`).update({
      unreadCount: increment(1),
      driverUnreadCount: 0
    })
    if (message.files) {
      for (const f of message.files) {
        const photoID = 'a' + new Date().getTime()
        const imageRef = this.$fire.storage.ref().child(photoID)
        await imageRef.put(await fetch(f.localUrl).then(r => r.blob()))
        f.url = await imageRef.getDownloadURL()
      }
    }
    this.$fire.firestore.collection(`rooms/${message.roomId}/messages`).add({
      _id: new Date().getTime(),
      content: message.content,
      senderId: getters.user,
      timestamp: new Date().toString().substring(16, 21),
      date: new Date().toLocaleDateString(),
      ...(message.files
        ? {
            files: message.files.map((file) => {
              return {
                name: file.name,
                size: file.size,
                type: file.type,
                extension: file.extension || file.type,
                url: file.url || file.localUrl,
                ...(file.audio
                  ? {
                      audio: file.audio,
                      duration: file.duration
                    }
                  : {})
              }
            })
          }
        : {})
    }).then().catch(e => console.error(e))
    const data = {
      _id: new Date().getTime(),
      content: message.content,
      senderId: state.session.id + '',
      timestamp: new Date().toString().substring(16, 21),
      date: new Date().toLocaleDateString()
    }
    const image = getters.avatar
    const room = getters.rooms.find(r => r.roomId === message.roomId)
    // eslint-disable-next-line eqeqeq
    const user = state.dispatchUsers.find(u => parseInt(u.id) === room.users[1]._id)
    const token = user && user.pushToken
    return this.$axios.$post('/messages', getFirebaseMessage(image, getters.user, message.content, token, data))
  }),
  bindMessages: firestoreAction(function ({ bindFirestoreRef }, roomId) {
    return bindFirestoreRef('messages',
      this.$fire.firestore.collection(`rooms/${roomId}/messages`).orderBy('_id'))
  }),
  bindRooms: firestoreAction(function ({
    state,
    getters,
    bindFirestoreRef
  }) {
    const rooms = state.users.map(d => `${getters.user}_${d.id}`)
    return bindFirestoreRef('rooms', this.$fire.firestore.collection('rooms')
      .where('roomId', 'in', rooms))
  }),
  bindDispatchUsers: firestoreAction(function ({
    bindFirestoreRef
  }) {
    return bindFirestoreRef('dispatchUsers', this.$fire.firestore.collection('dispatchUsers'))
  }),
  bindPhotos: firestoreAction(async function ({ bindFirestoreRef, state }) {
    const ref = this.$fire.firestore.collection(`jobs/${state.order.id}/photos`)
    await bindFirestoreRef('photos', ref, { wait: true })
  }),
  bindJobs: firestoreAction(function ({
    bindFirestoreRef
  }) {
    return bindFirestoreRef('jobs', this.$fire.firestore.collection('jobs'))
  }),
  unbindMessages: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('messages')
  }),
  unbindPhotos: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('photos')
  }),
  async getSession ({ commit }) {
    const session = await Auth.currentSession()
    commit('SET_SESSION', session)
    if (Capacitor.isNativePlatform()) {
      await Preferences.set({
        key: 'token',
        value: session.accessToken.getJwtToken()
      })
    }
  },
  connectWebSocket ({ commit, state, getters }) {
    socket = new WebSocket(`wss://${process.env.WEB_SOCKET_HOST || 'traccar-eu.fleetmap.pt'}/api/socket`)
    socket.onopen = () => socket.send(getters.traccarSession && getters.traccarSession.token)
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data)
      console.log('onmessage', data)
      if (state.device && data.positions &&
        data.positions.find(p => p.deviceId === state.device.id && p.attributes.event === 206)) {
        commit('SET_WAIT_UNLOCK', false)
      }
    }
    socket.onerror = e => console.error('socket error', e)
    socket.onclose = e => console.error('socket close', e)
  },
  async getTraccarSession ({ commit }) {
    await this.$backend.$get('session')
    try {
      commit('SET_TRACCAR_SESSION', await this.$traccar.$get('session'))
    } catch (e) {
      console.error(e)
    }
  },
  async loadDevice ({ commit }, id) {
    try {
      if (document.location.search && document.location.search.includes('token')) {
        commit('SET_DEVICE', await this.$axios.$get(document.location.search))
      } else {
        commit('SET_DEVICE', await this.$axios.$get('/?id=' + id))
      }
    } catch (e) {
    }
  },
  async initData ({ commit, dispatch }) {
    await dispatch('getSession')
    await dispatch('getTraccarSession')
    try {
      await dispatch('connectWebSocket')
    } catch (e) {
      console.error('connectWebSocket', e)
    }
    // commit('SET_USERS', await this.$axios.$get('/users'))
    // await dispatch('bindRooms')
    // await dispatch('bindDispatchUsers')
    const devices = await this.$axios.$get('/devices')
    devices.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
    commit('SET_DEVICES', devices)
    if (devices[0] && devices[0].attributes.clientId !== this.companyId) {
      commit('persisted/setCompanyId', devices[0].attributes.clientId)
    }
    commit('SET_COMPANY', await this.$axios.$get('/company'))
    // await dispatch('addPushToken')
    // await dispatch('bindJobs')
  }
}

export const getters = {
  waitUnlock: state => state.waitUnlock,
  avatar: state => 'https://ui-avatars.com/api/?name=' + (state.session && state.session.accessToken.payload.username),
  notification: state => state.notification,
  version: () => process.env.PACKAGE_VERSION,
  recoverPasswordEnabled: () => true,
  bluetoothEnabled: state => state.persisted.companyId === 1650,
  jobs: state => state.jobs,
  photos: (state) => { return state.photos },
  title: (state) => { return state.title },
  device: (state) => { return state.device },
  bluetoothDevice: (state) => { return state.bluetoothDevice },
  showTabbar: (state) => { return state.showTabbar },
  showBackButton: (state) => { return state.showBackButton },
  loading: (state) => { return state.loading },
  user: state => state.session && state.session.accessToken.payload.username,
  sessionIat: state => state.session && state.session.accessToken.payload.iat,
  companyId: state => state.persisted.companyId,
  users: state => state.users,
  rooms: state => state.rooms,
  messages: state => state.messages,
  devices: state => state.devices,
  pushToken: state => state.pushToken,
  traccarSession: state => state.traccarSession,
  unlockDisabled: state => state.traccarSession && state.traccarSession.attributes && state.traccarSession.attributes.driverAppUnlockDisabled,
  checkListEnabled: state => state.traccarSession && state.traccarSession.attributes && state.traccarSession.attributes.driverAppCheckListEnabled,
  sessionTimeout: state => state.traccarSession && state.traccarSession.attributes && state.traccarSession.attributes.driverAppSessionTimeout
}
