import { Capacitor } from '@capacitor/core'

export default async function ({ app, store }) {
  if (!Capacitor.isNativePlatform()) {
    if (navigator.userAgent.includes('Chrome')) {
      store.commit('SET_PUSH_TOKEN', await app.$fire.messaging.getToken())
    } else {
      console.log('ignoring', navigator.userAgent)
    }
  }
}
