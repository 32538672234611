export default async function ({
  store,
  route,
  app
}) {
  if (route && route.name && route.name.startsWith('login')) { return }
  try { await store.dispatch('getSession') } catch (e) {
    // eslint-disable-next-line no-console
    console.error(route, e)
    await app.router.push(app.localePath('/loginAmplify'))
  }
}
