import { Amplify } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'

const config = {
  aws_project_region: 'eu-west-3',
  aws_cognito_region: 'eu-west-3',
  aws_user_pools_id: 'eu-west-3_YOYKIhAio',
  aws_user_pools_web_client_id: '7i8l0je74sh91v3g0rv2s1io2d',
  oauth: {
    domain: 'driver.auth.eu-west-3.amazoncognito.com',
    redirectSignIn: process.env.REDIRECT_URI,
    redirectSignOut: process.env.LOGOUT_REDIRECT_URI,
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS',
  authenticationFlowType: 'USER_PASSWORD_AUTH'
}

Amplify.configure(config)
Auth.configure(config)

export default config
