import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { CaptureConsole } from '@sentry/integrations'

export default () => {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new CaptureConsole({ levels: ['error', 'warn'] })
    ]
  })
  console.info('sentry started')
}
