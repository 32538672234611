//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { NavBar, Notify, Tabbar, TabbarItem, Toast } from 'vant'
import { mapGetters } from 'vuex'
import { Capacitor } from '@capacitor/core'
import { checkUpdate, openAppStore, performImmediateUpdate } from '@/util/updates'

export default {
  name: 'DefaultLayout',
  components: { Tabbar, TabbarItem, NavBar },
  data () {
    return {
      active: 10
    }
  },
  computed: {
    ...mapGetters(['title', 'device', 'showTabbar', 'showBackButton', 'user'])
  },
  async mounted () {
    try {
      this.$store.commit('SET_LOADING', true)
      if (this.user) {
        this.$store.commit('SET_TITLE', this.user)
      }
      this.$nuxt.$on('newVersionAvailable', () => {
        Notify({
          message: this.$t('newVersion'),
          duration: 0,
          onClick: this.update
        })
      })
      if (Capacitor.isNativePlatform()) {
        checkUpdate(this.$nuxt).then()
      }
      await this.$store.dispatch('initData')
    } catch (e) {
      console.error(JSON.stringify(e))
      Toast(e.name)
    }
    this.$store.commit('SET_LOADING', false)
  },
  methods: {
    update () {
      if (Capacitor.getPlatform() === 'android') { performImmediateUpdate().then() } else { openAppStore().then() }
    },
    clickLeft () {
      this.$router.go(-1)
    }
  }
}
