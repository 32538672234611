// import { BleClient } from '@capacitor-community/bluetooth-le'
import { Capacitor } from '@capacitor/core'
const BleClient = {}
// import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial'
const BluetoothSerial = {}

async function readData () {
  let readData = await BluetoothSerial.read()
  while (!readData.length) {
    readData = await BluetoothSerial.read()
  }
  console.log('read', JSON.stringify(readData))
  return readData
}

let device = null

function setConfig (resolve, reject, value = 0) {
  BluetoothSerial.write(`:cfg_setparam:11700:${value}\r\n`).then(
    async (d) => {
      console.log('setparam result:', d)
      await readData()
      BluetoothSerial.write(':cfg_save\r\n').then(
        async (result) => {
          console.log('save result:', result)
          await readData()
          BluetoothSerial.disconnect().then(() => resolve()).catch(e => reject(e))
        },
        e => reject(e)
      )
    },
    (e) => {
      console.error('error writing setparam', e)
      reject(e)
    })
}

export default async function ({ app, store }, inject) {
  const i18n = app.i18n
  async function sendCommand (deviceName, command) {
    console.log('connect', deviceName)
    const bonded = await BluetoothSerial.list()
    console.log('bonded', JSON.stringify(bonded))
    if (bonded.length && bonded.find(b => b.name === deviceName.replace('_LE', ''))) {
      device = bonded.find(b => b.name === deviceName.replace('_LE', ''))
      console.log('connecting device', device)
      return new Promise((resolve, reject) => {
        BluetoothSerial.connect(device.id)
          .subscribe((e) => {
            console.log('received event', e)
            setConfig(resolve, reject, command)
          }, error => reject(error))
      })
    } else if (Capacitor.getPlatform() === 'android') {
      throw i18n.t('Por favor parear dispositivo')
    }
  }
  if (store.getters.bluetoothEnabled) {
    let enabled
    try {
      await BleClient.initialize()
      enabled = await BleClient.isEnabled()
    } catch (e) {
      enabled = false
    }
    if (!enabled) {
      alert(i18n.t('Por favor conectar el bluetooth: '))
    }
  }
  inject('bluetooth', {
    requestLEScan: callback => BleClient.requestLEScan({}, callback),
    connect: deviceName => sendCommand(deviceName, 0),
    disconnect: deviceName => sendCommand(deviceName, 3),
    getBonded: () => BluetoothSerial.list()
  })
}
