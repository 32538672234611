import { Capacitor, CapacitorHttp } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
const baseURL = 'https://fieldmap.net'

export default function ({ $axios, redirect }, inject) {
  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 401) {
      // redirect('/login')
    }
  })

  const traccar = $axios.create({
    baseURL: baseURL + '/api',
    withCredentials: true
  })

  const backend = $axios.create({
    baseURL: baseURL + '/backend',
    withCredentials: true
  })
  inject('traccar', traccar)
  inject('backend', backend)

  if (Capacitor.getPlatform() === 'ios') {
    traccar.$get = url => get(baseURL + '/api/' + url)
    backend.$get = url => get(baseURL + '/backend/' + url)
  }
}

async function get (url) {
  console.log(url)
  const Authorization = await Preferences.get({ key: 'token' }).then(r => r.value)
  const Cookie = await Preferences.get({ key: 'cookie' }).then(r => r.value)
  const response = await CapacitorHttp.request({
    url,
    headers: {
      Authorization,
      Cookie
    }
  })
  if (response.headers['Set-Cookie']) {
    await Preferences.set({
      key: 'cookie',
      value: response.headers['Set-Cookie'].substring(0, response.headers['Set-Cookie'].indexOf(';'))
    })
  }
  return response.data
}
