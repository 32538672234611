import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update'
import { Capacitor } from '@capacitor/core'

export const checkUpdate = async (nuxt) => {
  if (!Capacitor.isNativePlatform()) { return }
  const updateInfo = await AppUpdate.getAppUpdateInfo()
  const currVersion = await parseVersion(updateInfo.currentVersion)
  const availVersion = await parseVersion(await getAvailableAppVersion())
  if (currVersion < availVersion) {
    nuxt.$emit('newVersionAvailable')
    console.log(`new version available! ${currVersion} ${availVersion}`)
  } else {
    console.log(`no new version available! ${currVersion} ${availVersion}`)
  }
}

function parseVersion (version) {
  try {
    return parseInt(version.replace(/\./g, ''))
  } catch (e) {
    console.log('parseVersion: ' + e.message)
    console.error(e)
    return 0
  }
}

const getAvailableAppVersion = async () => {
  const result = await AppUpdate.getAppUpdateInfo()
  return result.availableVersion
}

export const openAppStore = async () => {
  await AppUpdate.openAppStore()
}

export const performImmediateUpdate = async () => {
  const result = await AppUpdate.getAppUpdateInfo()
  if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
    return
  }
  if (result.immediateUpdateAllowed) {
    await AppUpdate.performImmediateUpdate()
  }
}
