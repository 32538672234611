import { App } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions()

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions()
  }

  if (permStatus.receive !== 'granted') {
    console.error('User denied permissions!')
  }

  await PushNotifications.register()
}

export default async function ({ app, store }) {
  App.addListener('appUrlOpen', async (data) => {
    try { await Browser.close() } catch (e) { }
    await app.$auth.strategy._handleCallback(data.url.split('#')[1])
  })
  if (Capacitor.isNativePlatform()) {
    await PushNotifications.addListener('registration', (token) => {
      console.log('Registration token: ', token.value)
      store.commit('SET_PUSH_TOKEN', token.value)
    })

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error)
    })

    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.warn('Push notification received: ', notification)
    })

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      store.commit('SET_NOTIFICATION', notification)
      app.router.replace(app.localePath('/chat'))
    })
    await registerNotifications()
  }
}
